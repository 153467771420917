import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../app/features/cart/cartSlice";
import "./navbar.css";
import { FaStore, FaBuilding } from 'react-icons/fa';

const NavBar = () => {
  const { cartList } = useSelector((state) => state.cart);
  const [expand, setExpand] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [role, setRole] = useState(localStorage.getItem("role"));
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
    setIsModalOpen(true);
    }

    const closeModal = () => {
    setIsModalOpen(false);
    }

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem("token"));
    };

    // Listen for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial check for user
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const userInfo = JSON.parse(loggedInUser);
      setUser(userInfo);
      setRole(localStorage.getItem("role"));
    }

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };

  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
    window.location.href = "/login";
    dispatch(clearCart());
    setUser({});
  };

  const [reloaded, setReloaded] = useState("false");

  useEffect(() => {
    setInterval(() => {
      setReloaded(localStorage.getItem("reload"));
    }, 1000);
  }, [token, reloaded]);

  return (
      <>

        <div className="w-full px-4 text-gray-700 bg-white  newNavbar">
          <div className="container flex flex-col flex-wrap items-baseline justify-between py-1 px-0 md:flex-row max-w-7xl">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/shop" className="textLogo text-xl font-black leading-none text-gray-900 select-none navbarBtnLink navbarLogo">
                MediCompare
              </a>
              <button
                  onClick={toggleMenu}
                  className="navbarToggleBtn inline-flex items-center justify-center p-2 text-gray-700 rounded-md md:hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={!isOpen ? "M4 6h16M4 12h16m-7 6h7" : "M6 18L18 6M6 6l12 12"}
                  />
                </svg>
              </button>
            </div>

            <div
                className={`${
                    isOpen ? 'block' : 'hidden'
                } md:flex md:flex-row md:items-center w-full md:w-auto mt-4 md:mt-0 md:items-baseline newNavbBarLinks`}
            >
              <nav className="flex flex-col md:flex-row   md:border-gray-200">
                <a href="/shop" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">
                  Produits
                </a>

                {(role === "ROLE_ADMIN") && (
                    <a href="/store" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900 ">
                      Magasin
                    </a>
                )}

                {(token) && (
                  <a href="/profil" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900 ">
                    Profil
                  </a>
                )}

                {role === "ROLE_CLIENT" && (
                      <Nav.Link
                          as={Link}
                          to="/cart"
                          onClick={() => setExpand(false)}
                          className={"d-flex align-items-center cart-link"}
                      >
                        <span>
                          Panier
                        </span>
                    <span className="relative inline-flex items-center sm:p-2 lg:p-0 text-sm font-small text-center text-white bg-white-700 rounded-lg hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800">
                      <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                      >
                        <path
                            fillRule="evenodd"
                            d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z"
                            clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Panier</span>
                      <div className="cartListIcon absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold  border-2  rounded-full -top-2 -end-2 ">
                        {cartList.length}
                      </div>
                    </span>
                      </Nav.Link>
                )}


              </nav>

              {!token && (
                  <div className="flex flex-col  space-x-0 justify-between md:flex-row md:space-x-6 md:mt-0 navbarBtnLinks">
                    <a
                        href="/login"
                        className="navbarBtnLink inline-flex items-center justify-center px-4 py-2 mt-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-sky-500 border border-transparent rounded-md shadow-sm md:mt-0 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      Se connecter
                    </a>
                    <a
                        href="/register"
                        className="navbarBtnLink inline-flex items-center justify-center px-4 py-2 mt-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-sky-500 border border-transparent rounded-md shadow-sm md:mt-0 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      S'inscrire
                    </a>
                  </div>
              )}

              {token && (
                  <div className="flex   space-x-0 justify-between  md:space-x-6 md:mt-0 navbarBtnLinks">

                   <div className={"logoutDiv d-flex md:gap-4 flex-col"}>
                     {token && user.email && (
                         <small className="d-flex align-items-center email-link">
                           <span className="text-gray-800 dark:text-white">({user.email})</span>
                         </small>
                     )}
                     <a
                         onClick={openModal}
                         href="#"
                         className="navbarBtnLink logoutBtn inline-flex items-center justify-center px-4 py-2 mt-2  font-medium leading-6 text-white whitespace-no-wrap rounded-md shadow-sm md:mt-0 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                     >
                       Se déconnecter
                     </a>
                   </div>

                  </div>
              )}


            </div>
          </div>
        </div>


        {/*
          <Nav.Link
                          as={Link}
                          onClick={openModal}
                          className={"d-flex align-items-center text-danger logoutLink font-bold"}
                      >
                        Se Déconnecter
                        <svg
                            className="w-6 h-6 text-gray-800 text-danger"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                          <path
                              fillRule="evenodd"
                              d="M15 3a1 1 0 1 1 2 0v1h1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-1v1a1 1 0 1 1-2 0v-1h-4v1a1 1 0 1 1-2 0v-1H8a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V3a1 1 0 1 1 2 0v1h4V3ZM8 6v12h8V6H8Zm4 5.5a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2h2Z"
                              clipRule="evenodd"
                          />
                        </svg>
                      </Nav.Link>
        */}


        {isModalOpen && (
            <div id="popup-modal" tabIndex="-1" className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="p-4 md:p-5 text-center">
                    <h2 className="text-lg font-bold text-gray-900 dark:text-white">
                      Se déconnecter
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300">
                      Êtes-vous sûr de vouloir vous déconnecter ?
                    </p>
                    <div className="flex justify-center mt-4 gap-2">
                      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleLogout}>
                        Se déconnecter
                      </button>
                      <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={closeModal}>
                        Fermer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}


      </>
  );
};

export default NavBar;
