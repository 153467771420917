import React, {useEffect, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaStore, FaBuilding } from 'react-icons/fa';
import "./style.css";

const Footer = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();
  const { cartList } = useSelector((state) => state.cart);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const role = localStorage.getItem("role");

  const [showCookies, setShowCookies] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookiesAccepted")) {
      setShowCookies(true);
    }
  }, []);

  function acceptCookies() {
    setShowCookies(false);
    localStorage.setItem("cookiesAccepted", "true");
  }

  function rejectCookies() {
    setShowCookies(false);
    localStorage.setItem("cookiesAccepted", "false");
  }

  return (
      <footer>
        {/*{showCookies && <div className="dark-overlay"></div>}

        {showCookies &&
            <div className="containerCookies">
              <div>
                <div className=" bg-white rounded-lg shadow-md p-6">
                  <div className="w-20 mx-auto relative -mt-16 mb-3">
                    <img className="-mt-1" src="https://www.svgrepo.com/show/30963/cookie.svg" alt="Cookie Icon SVG"/>
                  </div>
                  <span className="w-full sm:w-52  block leading-normal text-gray-800 text-md mb-3">
                Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web.
              </span>
                  <div className="flex items-center justify-between mb-2">
                    <a className="text-xs text-gray-400 mr-1 hover:text-gray-800" href="#">
                      Politique de confidentialité
                    </a>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <button type="button"
                            onClick={() => rejectCookies()}
                            className="py-2 px-4  bg-red-500 hover:bg-red-600 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Refuser
                    </button>
                    <button type="button"
                            onClick={() => acceptCookies()}
                            className="py-2 px-4  bg-sky-500 hover:bg-sky-600 focus:ring-sky-500 focus:ring-offset-sky-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Accepter
                    </button>
                  </div>
                </div>
              </div>
            </div>
        }
*/}

        <Container>
          <Row className="footer-row">
            <Col md={3} sm={5} className="box">
              <h1>MediCompare</h1>
              <p>
                MediCompare est un comparateur de prix qui vous permet de trouver les meilleurs prix pour tous vos produits
                dentaires.
              </p>
            </Col>

            <Col md={3} sm={5} className="box">
              <ul>
                <li>
                  <Link to="/">Accueil</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <a href="/shop" target="_blank" rel="noopener noreferrer">Visiter le comparateur</a>
                </li>
              </ul>
            </Col>

            <Col md={3} sm={5} className="box">
              <ul>
                <li>
                  <Link to="/shop">Produits</Link>
                </li>
                {role === "ROLE_ADMIN" && (
                    <li>
                      <Link to="/store">
                        Magasin <FaStore style={{fontSize: "1rem", marginLeft: "4px"}}/>
                      </Link>
                    </li>
                )}
                {token && (
                    <>
                      <li>
                        <Link to="/profil">Profil</Link>
                      </li>
                      <li>
                        <Link to="/cart">Panier ({cartList.length})</Link>
                      </li>
                    </>
                )}
              </ul>
            </Col>

            {!token && (
                <Col md={3} sm={5} className="box">
                  <ul>
                    <li>
                      <Link to="/login">
                        Connexion
                      </Link>
                    </li>
                    <li>
                      <Link to="/register">
                        Inscription
                      </Link>
                    </li>

                  </ul>
                </Col>
            )}
          </Row>

          <Row>
            <Col className="text-center mt-4">
              <p className="font-bold">© {currentYear} MediCompare. Tous droits réservés.</p>
            </Col>
          </Row>
        </Container>
      </footer>
  );
};

export default Footer;
