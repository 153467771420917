import { createSlice } from "@reduxjs/toolkit";

// Initialize cartList from localStorage
const storedCartList =
    localStorage.getItem("cartList") !== null
        ? JSON.parse(localStorage.getItem("cartList"))
        : [];

const initialState = {
  cartList: storedCartList,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const productToAdd = action.payload.product;
      const quantity = action.payload.num;
      const productExit = state.cartList.find(
          (item) => item.id === productToAdd.id
      );
      if (productExit) {
        state.cartList = state.cartList.map((item) =>
            item.id === productToAdd.id
                ? { ...productExit, qty: productExit.qty + action.payload.num }
                : item
        );
      } else {
        state.cartList.push({ ...productToAdd, qty: quantity });
      }
    },
    decreaseQty: (state, action) => {
      const productTodecreaseQnty = action.payload;
      const productExit = state.cartList.find(
          (item) => item.id === productTodecreaseQnty.id
      );
      if (productExit.qty === 1) {
        state.cartList = state.cartList.filter(
            (item) => item.id !== productExit.id
        );
      } else {
        state.cartList = state.cartList.map((item) =>
            item.id === productExit.id
                ? { ...productExit, qty: productExit.qty - 1 }
                : item
        );
      }
    },
    increaseQty: (state, action) => {
      const productToIncreaseQnty = action.payload;
      const productExit = state.cartList.find(
          (item) => item.id === productToIncreaseQnty.id
      );
      state.cartList = state.cartList.map((item) =>
          item.id === productExit.id
              ? { ...productExit, qty: productExit.qty + 1 }
              : item
      );
    },
    deleteProduct: (state, action) => {
      const productToDelete = action.payload;
      state.cartList = state.cartList.filter(
          (item) => item.id !== productToDelete.id
      );
    },
    clearCart: (state) => {
      state.cartList = [];
    },
    setCartItems: (state, action) => {
      const items = action.payload;

      const mergedItemsMap = new Map();

      items.forEach((item) => {
        if (mergedItemsMap.has(item.reference)) {
          const existingItem = mergedItemsMap.get(item.reference);
          mergedItemsMap.set(item.reference, {
            ...existingItem,
            qty: existingItem.qty + item.quantity,
          });
        } else {
          mergedItemsMap.set(item.reference, { ...item, qty: item.quantity });
        }
      });

      state.cartList = Array.from(mergedItemsMap.values());
    },
  },
});

// Middleware to sync cartList with localStorage
export const cartMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type?.startsWith("cart/")) {
    const cartList = store.getState().cart.cartList;
    if (action.type === 'cart/clearCart') {
      localStorage.removeItem("cartList");
    } else {
      localStorage.setItem("cartList", JSON.stringify(cartList));
    }
  }
  return result;
};

export const { addToCart, decreaseQty , increaseQty, deleteProduct, clearCart, setCartItems } = cartSlice.actions;
export default cartSlice.reducer;
